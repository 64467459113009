import Icon from 'components/utils/Icon';
import useTranslation from 'hooks/useTranslation';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import NavItem from './NavItem';
import style from './sidebarFree.style';
import useSidebarMenu from '../../hooks/useSidebarMenu';
import { Link } from 'components/utils/Link';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function SidebarFree({ menuOpen, menuOpenHandler }) {
  const { TRANSLATIONS } = useTranslation();
  const router = useRouterWithLang();
  const user = useSelector((state) => state.user);
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const PERMISSIONS = useSelector((state) => state.permissions);
  const handleRouteChange = useCallback(
    (route) => {
      router.push(route);
      if (menuOpen) menuOpenHandler();
    },
    [menuOpen, menuOpenHandler, router]
  );
  const { menuItems } = useSidebarMenu();
  const userCatalog = useSelector((state) => state?.user?.userCatalogs)?.[0];

  return (
    <div className={`relative`}>
      <div
        className={menuOpen ? 'overlay' : ''}
        onClick={() => {
          if (menuOpen) {
            menuOpenHandler();
          }
        }}
      />
      <div>
        <aside className={`sidenav ${menuOpen ? 'active' : 'inactive'}`}>
          <div className="sidenav__top-section relative">
            {isLoggedIn && (
              <div
                className="user-menu-title d-f fd-c fw-w  g-5"
                onClick={() => {
                  handleRouteChange('/profile/general');
                }}
              >
                <p>
                  {TRANSLATIONS.home.titleRoleUSer} {user?.name}
                </p>
                <div>
                  <span className={`role-user`}>{user?.roles?.[0].name}</span>
                </div>
              </div>
            )}
            {!isLoggedIn && (
              <>
                <div className="menu-wrap-center-logo ai-c d-f"></div>
              </>
            )}
            <div
              className="close-btn"
              onClick={menuOpenHandler}
              style={{ cursor: 'pointer' }}
            >
              <Icon
                type="x"
                style={{ fontSize: 26, color: 'var(--foreground)' }}
              />
            </div>
          </div>
          <div>
            <ul className="menu">
              {menuItems
                ?.filter((item) => !item?.hidden)
                ?.map((item, i) => (
                  <NavItem
                    key={item.label}
                    item={item}
                    handleRouteChange={handleRouteChange}
                  />
                ))}
            </ul>
            <ul className="menu">
              <h5>{TRANSLATIONS.menuItem.topBrands}</h5>
              {TRANSLATIONS.menuItem.specialPromo !== null && (
                <li>
                  <div
                    onClick={() =>
                      handleRouteChange(
                        PERMISSIONS.IS_PRIVATE
                          ? `/channel/${userCatalog?._id}/edit/products?${TRANSLATIONS.menuItem.specialPromo}`
                          : `/product?${TRANSLATIONS.menuItem.specialPromoLink}`
                      )
                    }
                  >
                    <div
                      className={'item'}
                      style={{ color: '#ff3f35', fontWeight: 700 }}
                    >
                      {TRANSLATIONS.menuItem.specialPromo}
                    </div>
                  </div>
                </li>
              )}
              {TRANSLATIONS.menuItem.brand_1 !== null && (
                <li>
                  <div
                    onClick={() =>
                      handleRouteChange(
                        PERMISSIONS.IS_PRIVATE
                          ? `/channel/${userCatalog?._id}/edit/products?brand=${TRANSLATIONS.menuItem.brand_1}&availability=1`
                          : `/product?brand=${TRANSLATIONS.menuItem.brand_1}&availability=1`
                      )
                    }
                  >
                    <div className={'item'}>
                      {TRANSLATIONS.menuItem.brand_1}
                    </div>
                  </div>
                </li>
              )}
              {TRANSLATIONS.menuItem.brand_2 !== null && (
                <li>
                  <div
                    onClick={() =>
                      handleRouteChange(
                        PERMISSIONS.IS_PRIVATE
                          ? `/channel/${userCatalog?._id}/edit/products?brand=${TRANSLATIONS.menuItem.brand_2}&availability=1`
                          : `/product?brand=${TRANSLATIONS.menuItem.brand_2}&availability=1`
                      )
                    }
                  >
                    <div className={'item'}>
                      {TRANSLATIONS.menuItem.brand_2}
                    </div>
                  </div>
                </li>
              )}
              {TRANSLATIONS.menuItem.brand_3 !== null && (
                <li>
                  <div
                    onClick={() =>
                      handleRouteChange(
                        PERMISSIONS.IS_PRIVATE
                          ? `/channel/${userCatalog?._id}/edit/products?brand=${TRANSLATIONS.menuItem.brand_3}&availability=1`
                          : `/product?brand=${TRANSLATIONS.menuItem.brand_3}&availability=1`
                      )
                    }
                  >
                    <div className={'item'}>
                      {TRANSLATIONS.menuItem.brand_3}
                    </div>
                  </div>
                </li>
              )}
              {TRANSLATIONS.menuItem.brand_4 !== null && (
                <li>
                  <div
                    onClick={() =>
                      handleRouteChange(
                        PERMISSIONS.IS_PRIVATE
                          ? `/channel/${userCatalog?._id}/edit/products?brand=${TRANSLATIONS.menuItem.brand_4}&availability=1`
                          : `/product?brand=${TRANSLATIONS.menuItem.brand_4}&availability=1`
                      )
                    }
                  >
                    <div className={'item'}>
                      {TRANSLATIONS.menuItem.brand_4}
                    </div>
                  </div>
                </li>
              )}
              {TRANSLATIONS.menuItem.brand_5 !== null && (
                <li>
                  <div
                    onClick={() =>
                      handleRouteChange(
                        PERMISSIONS.IS_PRIVATE
                          ? `/channel/${userCatalog?._id}/edit/products?brand=${TRANSLATIONS.menuItem.brand_5}&availability=1`
                          : `/product?brand=${TRANSLATIONS.menuItem.brand_5}&availability=1`
                      )
                    }
                  >
                    <div className={'item'}>
                      {TRANSLATIONS.menuItem.brand_5}
                    </div>
                  </div>
                </li>
              )}
              {TRANSLATIONS.menuItem.brand_6 !== null && (
                <li>
                  <div
                    onClick={() =>
                      handleRouteChange(
                        PERMISSIONS.IS_PRIVATE
                          ? `/channel/${userCatalog?._id}/edit/products?brand=${TRANSLATIONS.menuItem.brand_6}&availability=1`
                          : `/product?brand=${TRANSLATIONS.menuItem.brand_6}&availability=1`
                      )
                    }
                  >
                    <div className={'item'}>
                      {TRANSLATIONS.menuItem.brand_6}
                    </div>
                  </div>
                </li>
              )}
              {TRANSLATIONS.menuItem.brand_7 !== null && (
                <li>
                  <div
                    onClick={() =>
                      handleRouteChange(
                        PERMISSIONS.IS_PRIVATE
                          ? `/channel/${userCatalog?._id}/edit/products?brand=${TRANSLATIONS.menuItem.brand_7}&availability=1`
                          : `/product?brand=${TRANSLATIONS.menuItem.brand_7}&availability=1`
                      )
                    }
                  >
                    <div className={'item'}>
                      {TRANSLATIONS.menuItem.brand_7}
                    </div>
                  </div>
                </li>
              )}
            </ul>
            <div>
              <ul className="menu ">
                <h5>{TRANSLATIONS.menuItem.resources}</h5>
                <Link href={'/brands'}>
                  <li>
                    <div>
                      <div className={'item'}>
                        {TRANSLATIONS.menuItem.brands}
                      </div>
                    </div>
                  </li>
                </Link>
                <Link href="/pricing">
                  <li>
                    <div>
                      <div className={'item'}>
                        {TRANSLATIONS.menuItem.reseller}
                      </div>
                    </div>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </aside>
      </div>
      <style jsx>{style}</style>
    </div>
  );
}

export default SidebarFree;
